import type { LineItem } from '@containex/portal-backend-api-client';
import { partition } from '@containex/common-utils';
import { CONTAINER_ARTICLE_TYPES } from '@containex/portal-business-logic';
import { compareAsc, parseISO } from 'date-fns';

export function lineItemsWithoutChildsSortedByDateAndContainersAndAdditionalProducts(
    lineItems: LineItem[]
): LineItem[] {
    const filteredItems = lineItemsWithoutChilds(lineItems);
    const sortedLineItems = sortLineItemsByCreationDate(filteredItems);

    const groupedLineItems = partition(sortedLineItems, (item) =>
        CONTAINER_ARTICLE_TYPES.includes(item.variant?.product?.article_type ?? '')
    );

    return [...groupedLineItems[0], ...groupedLineItems[1]];
}

export function isAdditionalProduct(articleType: string): boolean {
    return !CONTAINER_ARTICLE_TYPES.includes(articleType);
}

export function lineItemsWithoutChilds(lineItems: LineItem[]): LineItem[] {
    return lineItems.filter((item) => item.parent_line_item_id == null);
}

export function sortLineItemsByCreationDate(lineItems: LineItem[]): LineItem[] {
    return lineItems.sort((a, b) => {
        const aDate = typeof a.created_at === 'string' ? parseISO(a.created_at) : a.created_at;

        const bDate = typeof b.created_at === 'string' ? parseISO(b.created_at) : b.created_at;

        return compareAsc(aDate, bDate);
    });
}
